import React from "react"
import styled from "styled-components"

const LogoTooltip = ({ country, logoSrc, className }) => {
  return (
    <Wrapper data-country={country} className={className}>
      <Logo src={logoSrc} />
      <Hint>Show details</Hint>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  width: 100%;
  height: 153px;
  padding-bottom: 40px;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 10px 30px rgba(0, 11, 33, 0.08);
  pointer-events: none;
`

const Logo = styled.img`
  width: 120px;

  [data-country="uk"]${Wrapper} &,
  [data-country="united-kingdom"]${Wrapper} &,
  [data-country="poland"]${Wrapper} & {
    width: 90px;
  }

  [data-country="poland"]${Wrapper} & {
    width: 90px;
  }

  [data-country="lithuania"]${Wrapper} & {
    width: 87px;
  }

  [data-country="belarus"]${Wrapper} & {
    width: 105px;
  }

  [data-country="ukraine"]${Wrapper} & {
    width: 100px;
  }

  [data-country="russia"]${Wrapper} & {
    width: 110px;
  }

  [data-country="france"]${Wrapper} & {
    width: 84px;
  }
`

const Hint = styled.p`
  display: inline-block;
  height: 30px;
  padding: 0 20px;
  position: absolute;
  bottom: 20px;
  /* background-image: linear-gradient(-20deg, #3ab6da, #50d9d6); */
  background: #f4f6f7;
  /* border: 1px solid ${(p) => p.theme.color.primary}; */
  border-radius: 3px;
  font-weight: 600;
  font-size: 13px;
  line-height: 30px;
  color: ${(p) => p.theme.color.text};
  color: #000;
  cursor: pointer;
  user-select: none;
  /* transition: 0.15s; */
`

export default LogoTooltip
