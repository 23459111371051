import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import styled from "styled-components"
import { media } from "src/styles/breakpoints"
import Seo from "src/components/SEO"
import BaseContainer from "src/components/Base/BaseContainer"
import BaseIcon from "src/components/Base/BaseIcon"
import Gallery from "src/components/Gallery"
import DetailsBar from "src/components/DetailsBar"
import LogoTooltip from "src/components/LogoTooltip"
import {
  PrimaryTitle,
  SecondaryTitle,
  TertiaryTitle,
} from "src/components/Typography"
import { slugify } from "src/utils/helpers"
import map from "src/assets/images/map-full.png"
import map_2x from "src/assets/images/map-full@2x.png"

export const pageQuery = graphql`
  {
    allStrapiOrganisation(
      sort: { fields: country___name }
      filter: { name: { nin: "Anima International" } }
    ) {
      nodes {
        name
        original_name
        country {
          name
        }
        logo {
          url
        }
      }
    }
  }
`

const Organisation = ({ pageContext, data }) => {
  const {
    name,
    original_name: originalName,
    country,
    website_url: url,
    description,
    work,
    current_projects: currentProjects,
    alliances,
    gallery,
    achievements,
  } = pageContext

  const organisations = data.allStrapiOrganisation.nodes

  const [observed, setObserved] = useState()
  const title = originalName || name
  const subtitle = originalName
    ? originalName === name
      ? country.name
      : name
    : country.name
  const urlShort = url?.replace("https://", "").replace("/", "")

  const detailsData = [
    {
      title: `About ${country.name}`,
      descriptionList: [
        ...country.country_facts.map((fact) => {
          return {
            title: fact.fact_item,
            details: fact.fact_value?.replace(
              /(thousand tons)|(thousand)|(million)|(billion)/,
              "<small>$1$2$3$4</small>"
            ),
            info: fact.info,
          }
        }),
      ],
    },
    {
      title: "Our work",
      list: [...work.map((item) => item.title)],
    },
    {
      title: "Current projects",
      projectList: [...currentProjects],
    },
    {
      title: "Alliances",
      list: [...alliances.map((item) => item.alliance_name)],
    },
  ]

  const galleryImages = gallery.map((item) => item.url)

  useEffect(() => {
    setObserved(true)
  }, [])

  return (
    <Wrapper className={observed ? "-observed" : ""}>
      <Container>
        <Seo title={name} />
        <Header>
          <Title as="h1">{title}</Title>
          <Subtitle>{subtitle}</Subtitle>
          <SiteLink href={url} target="_blank" rel="noopener noreferrer">
            {urlShort} <SiteLinkIcon name="external-link" source="feather" />
          </SiteLink>
          <BackButton to="/about/organisations">
            <BackButtonIcon name="corner-down-left" source="feather" />
            Back
          </BackButton>
        </Header>
        <Columns>
          <Column>
            <Description>
              <p dangerouslySetInnerHTML={{ __html: description }} />
            </Description>
            {gallery && <StyledGallery images={galleryImages} />}
            {/*<MoreFunding>
              <TertiaryTitle>Room for more funding</TertiaryTitle>
              <p>{moreFunding}</p>
            </MoreFunding>*/}
          </Column>
          <Column>
            <DetailsBar data={detailsData} />
          </Column>
        </Columns>
        {achievements.length > 0 && (
          <Achievements>
            <TertiaryTitle>Achievements</TertiaryTitle>
            <AchievementList>
              {achievements.map(({ info }, index) => (
                <AchievementListItem key={index}>
                  <AchievementListIcon name="check-circle" source="feather" />
                  <span dangerouslySetInnerHTML={{ __html: info }} />
                </AchievementListItem>
              ))}
            </AchievementList>
            <StyledLink to="/work/achievements">
              See more
              <Icon name="arrow-right" />
            </StyledLink>
          </Achievements>
        )}
      </Container>
      <OrganisationsMenu>
        <MapWrapper>
          <Map src={map} srcSet={`${map_2x} 2x`} />
        </MapWrapper>
        <OrganisationsMenuContainer>
          <OrganisationsMenuTitle>
            Organisations of Anima International
          </OrganisationsMenuTitle>
          <OrganisationsMenuItems>
            {organisations.map(
              ({ name, original_name, country, logo }, index) => {
                const slug = slugify(name)
                const logoSrc = logo.length && logo[0].url

                return (
                  <OrganisationsMenuItem key={index}>
                    <OrganisationsMenuLink to={`/about/organisations/${slug}`}>
                      <OrganisationsMenuCountry>
                        {country?.name}
                      </OrganisationsMenuCountry>
                      <OrganisationsMenuName>
                        {original_name || name}
                      </OrganisationsMenuName>
                      <MenuItemTooltip
                        country={country?.name?.toLowerCase()}
                        logoSrc={logoSrc}
                      />
                    </OrganisationsMenuLink>
                  </OrganisationsMenuItem>
                )
              }
            )}
          </OrganisationsMenuItems>
        </OrganisationsMenuContainer>
      </OrganisationsMenu>
    </Wrapper>
  )
}

const Wrapper = styled.div``

const Container = styled(BaseContainer)`
  margin-top: 180px;
  /* margin-bottom: 110px; */

  ${media.tablet} {
    margin-top: 250px;
    /* margin-bottom: 160px; */
  }
`

const Header = styled.div`
  margin-bottom: 60px;
  position: relative;
  text-align: center;

  > :not(:last-child) {
    transition: 0.6s ${(p) => p.theme.easing.easeOutQuart};
    transition-property: opacity, transform;

    div${Wrapper}:not(.-observed) & {
      opacity: 0;
      transform: translateY(20px);
    }
  }

  > :nth-child(2) {
    transition-delay: 0.15s;
  }

  > :nth-child(3) {
    transition-delay: 0.3s;
  }

  ${media.tablet} {
    margin-bottom: 90px;
  }
`

const Title = styled(PrimaryTitle)``

const Subtitle = styled.h3`
  margin-top: -38px;
  margin-bottom: 7px;
  font-weight: 400;
  font-size: 15px;
  color: ${(p) => p.theme.color.textLight};
`

const SiteLink = styled.a`
  display: inline-block;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 0.1em;
  /* font-size: 15px; */
  /* color: ${(p) => p.theme.color.textLight}; */
  color: ${(p) => p.theme.color.primary};
  /* color: #46cad8; */
  /* transition: .15s; */

  &:hover {
    color: #46cad8;
  }
`

const SiteLinkIcon = styled(BaseIcon)`
  margin-left: 3px;
  position: relative;
  top: -2px;
  width: 16px;
  height: 16px;
  /* color: #46cad8; */
`

const BackButton = styled(Link)`
  display: none;
  padding: 0 25px;
  position: absolute;
  top: 0;
  right: -25px;
  border: 1px solid transparent;
  border-radius: 25px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
  line-height: 50px;
  letter-spacing: 0.1em;
  /* color: ${(p) => p.theme.color.textLight}; */
  color: #8c9d9d;

  &:hover {
    border-color: #cfdbdb;
  }

  ${media.tablet} {
    display: inline-block;
  }
`

const BackButtonIcon = styled(BaseIcon)`
  margin-left: -5px;
  margin-right: 10px;

  ${BackButton}:hover & {
    color: #46cad8;
  }
`

const Columns = styled.div`
  margin-bottom: 110px;

  ${media.tablet} {
    margin-bottom: 150px;
  }

  ${media.desktop} {
    display: flex;
    margin-bottom: 0;
  }
`

const Column = styled.div`
  &:last-child {
    overflow: hidden;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
  }

  ${media.tablet} {
    &:last-child {
      min-width: 345px;
      overflow: visible;
    }
  }

  ${media.desktop} {
    &:last-child {
      max-width: none;
      margin-left: 80px;
    }
  }

  ${media.desktopMedium} {
    &:last-child {
      margin-left: 90px;
    }
  }
`

const Description = styled.div`
  margin-bottom: 80px;
  white-space: pre-line;

  strong {
    color: #000;
  }
`

const StyledGallery = styled(Gallery)`
  margin-left: -${(p) => p.theme.sidePadding.phone}px;
  margin-right: -${(p) => p.theme.sidePadding.phone}px;
  margin-bottom: 110px;

  ${media.tablet} {
    margin-left: -${(p) => p.theme.sidePadding.tablet}px;
    margin-right: -${(p) => p.theme.sidePadding.tablet}px;
    margin-bottom: 150px;
  }

  ${media.desktop} {
    width: 500px;
    margin-left: 0;
    margin-right: 0;
  }

  ${media.desktopMedium} {
    width: 605px;
  }
`

// const MoreFunding = styled.div`
//   margin-bottom: 150px;
// `

const Achievements = styled.div`
  /* padding: 50px; */
  /* border: 1px solid #cfdbdb; */
  /* border-radius: 3px; */

  margin-bottom: 110px;

  ${media.tablet} {
    margin-bottom: 160px;
  }
`

const AchievementList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  padding-top: 14px;
  font-size: 15px;
  line-height: 1.6;
  color: ${(p) => p.theme.color.textLight};

  strong {
    font-weight: 600;
    color: #000;
  }

  &:not(:last-child) {
    margin-bottom: 30px;
  }
`

const AchievementListItem = styled.li`
  &:not(:last-child) {
    margin-bottom: 25px;
  }

  p {
    display: inline;
  }

  ${media.tablet} {
    width: 50%;
    padding-right: 10px;
  }
`

const AchievementListIcon = styled(BaseIcon)`
  width: 21px;
  height: 21px;
  margin-right: 20px;
  color: #46cad8;
`

const StyledLink = styled(Link)`
  color: #076262;
`

const Icon = styled(BaseIcon)`
  width: 13px;
  height: 13px;
  margin-top: -1px;
  margin-left: 17px;
`

const OrganisationsMenu = styled.div`
  margin-bottom: 140px;
  padding-top: 90px;
  position: relative;
  /* border-top: 1px solid rgba(0, 11, 33, 0.1); */

  &::before,
  &::after {
    content: "";
    width: 100%;
    height: 1000px;
    position: absolute;
    top: 0;
    left: 0;
  }

  &::before {
    background: #f4f6f7;
  }

  &::after {
    background: linear-gradient(
      transparent 0,
      transparent 200px,
      rgba(255, 255, 255, 0) 200px,
      rgba(255, 255, 255, 1) 1200px
    );
  }

  ${media.tablet} {
    margin-bottom: 200px;
    padding-top: 150px;
  }
`

const MapWrapper = styled.div`
  width: 100%;
  min-height: 960px;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
`

const Map = styled.img`
  width: 2200px;
  max-width: none;
  position: absolute;
  top: -100px;
  left: 50%;
  transform: translateX(calc(-50% + 120px));
`

const OrganisationsMenuContainer = styled(BaseContainer)`
  position: relative;
  z-index: 1;
`

const OrganisationsMenuTitle = styled(SecondaryTitle)`
  margin-bottom: 80px;
  text-align: center;
`

const OrganisationsMenuItems = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: -20px;
  margin-right: -20px;
  font-size: 15px;
`

const OrganisationsMenuItem = styled.li`
  width: 100%;
  position: relative;

  ${media.tablet} {
    width: 44%;
  }

  ${media.desktop} {
    width: 31%;
  }

  ${media.desktopMedium} {
    width: 29%;
  }
`

const OrganisationsMenuLink = styled(Link)`
  display: flex;
  justify-content: space-between;
  padding: 11px 20px;
  position: relative;
  border-radius: 3px;

  &:hover {
    background: #d5f5f3;
  }
`

const OrganisationsMenuCountry = styled.span`
  font-weight: 500;
  color: #000;
`

const OrganisationsMenuName = styled.span`
  color: ${(p) => p.theme.color.textLight};
`

const MenuItemTooltip = styled(LogoTooltip)`
  ${media.pointer} {
    ${OrganisationsMenuLink}:hover & {
      visibility: visible;
    }
  }
`

export default Organisation
