import React from "react"
import styled from "styled-components"
import { media } from "src/styles/breakpoints"
import BaseIcon from "src/components/Base/BaseIcon"

const DetailsBar = ({ data }) => {
  return (
    <Wrapper>
      {data.map(
        ({ title, text, list, descriptionList, projectList }, index) => (
          <Section key={index}>
            <Title>{title}</Title>
            {text && <Text>{text}</Text>}
            {list && (
              <List>
                {list.map((listItem, listIndex) => (
                  <ListItem key={listIndex}>{listItem}</ListItem>
                ))}
              </List>
            )}
            {descriptionList && (
              <DescriptionList>
                {descriptionList.map(({ title, details, info }, listIndex) => {
                  return (
                    <React.Fragment key={listIndex}>
                      <DescriptionListTitle>{title}</DescriptionListTitle>
                      <DescriptionListDetails>
                        <span dangerouslySetInnerHTML={{ __html: details }} />
                        {info && (
                          <InfoMark>
                            <InfoMarkIcon name="question" />
                            <InfoMarkTooltip
                              dangerouslySetInnerHTML={{ __html: info }}
                            />
                          </InfoMark>
                        )}
                      </DescriptionListDetails>
                    </React.Fragment>
                  )
                })}
              </DescriptionList>
            )}
            {projectList && (
              <ProjectList>
                {projectList.map(({ icon, title }, listIndex) => (
                  <ProjectListItem key={listIndex}>
                    <ProjectListItemInner>
                      <ProjectListIcon name={icon} />
                      <ProjectListTooltip>{title}</ProjectListTooltip>
                    </ProjectListItemInner>
                  </ProjectListItem>
                ))}
              </ProjectList>
            )}
          </Section>
        )
      )}
    </Wrapper>
  )
}

const Wrapper = styled.aside`
  background: #fff;
  padding: 30px;
  position: relative;
  background: linear-gradient(-20deg, #3ab6da, #50d9d6);
  border-radius: 3px;
  box-shadow: 0 40px 60px rgba(0, 11, 33, 0.06);
  font-size: 15px;
  /* line-height: 1.6; */
  color: ${(p) => p.theme.color.textLight};

  &::before {
    content: "";
    position: absolute;
    top: 3px;
    right: 3px;
    bottom: 3px;
    left: 3px;
    background: #fff;
  }

  ${media.tablet} {
    padding: 45px;
  }
`

const Section = styled.div`
  position: relative;

  &:not(:last-child) {
    margin-bottom: 50px;
  }
`

const Title = styled.h3`
  margin-bottom: 10px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 13px;
  /* line-height: 1.6; */
  letter-spacing: 0.07em;
  color: #000;

  ~ *:not(:last-child) {
    margin-bottom: 1em;
  }
`

const Text = styled.p``

const List = styled.ul``

const ListItem = styled.li``

const DescriptionList = styled.dl`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`

const DescriptionListTitle = styled.dt`
  min-width: 50%;
`

const DescriptionListDetails = styled.dd`
  position: relative;
  font-weight: 600;
  color: #000;

  small {
    font-size: 12px;
  }
`

const InfoMark = styled.span`
  width: 24px;
  height: 24px;
  position: absolute;
  top: 1px;
  right: -24px;
  color: #8c9d9d;
`

const InfoMarkIcon = styled(BaseIcon)`
  opacity: 0.5;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 6px;
  left: 6px;
  transition: 0.15s;

  ${InfoMark}:hover & {
    opacity: 1;
  }
`

const Tooltip = styled.span`
  visibility: hidden;
  opacity: 0;
  /* min-width: 120px; */
  padding: 7px 20px;
  position: absolute;
  top: 30px;
  left: 0;
  z-index: 1;
  transform: translateX(-50%) translateY(-5px);
  background: rgba(0, 0, 0, 0.9);
  border-radius: 3px;
  font-weight: ${(p) => p.theme.basefontWeight};
  font-size: 12px;
  white-space: nowrap;
  color: #fff;
  transition: 0.15s;

  &::before {
    content: "";
    position: absolute;
    top: -5px;
    left: calc(50% + 7px);
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid rgba(0, 0, 0, 0.9);
  }
`

const InfoMarkTooltip = styled(Tooltip)`
  ${InfoMark}:hover & {
    visibility: visible;
    opacity: 1;
    transform: translateX(-50%);
  }
`

const ProjectList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
  margin-bottom: -10px;
`

const ProjectListItem = styled.li`
  width: calc((100% - 10px * 5) / 6);
  max-width: 35px;
  margin-bottom: 10px;
  position: relative;
  border: 1px solid #cfdbdb;
  border-radius: 3px;
  color: #8c9d9d;

  &:not(:nth-child(6n + 1)) {
    margin-left: 10px;
  }

  &:hover {
    background: #46cad8;
    border-color: transparent;
    color: #fff;
  }
`

const ProjectListItemInner = styled.div`
  padding-top: 100%;
`

const ProjectListIcon = styled(BaseIcon)`
  width: 16px;
  height: 16px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &[data-name="fox"] {
    width: 18px;
  }

  &[data-name="chick"] {
    width: 15px;
    height: 15px;
  }
`

const ProjectListTooltip = styled(Tooltip)`
  top: 43px;
  left: 50%;

  &::before {
    left: calc(50% - 4px);
  }

  ${ProjectListItem}:hover & {
    visibility: visible;
    opacity: 1;
    transform: translateX(-50%);
  }
`

export default DetailsBar
